<template>
  <l-map
    :zoom="zoomMap"
    :center="[longitude, latitude]"
    :options="{ zoomControl: false }"
  >
    <l-tile-layer :url="url" />
    <v-geosearch :options="geosearchOptions" />
    <l-marker :lat-lng="[longitude, latitude, { draggable: draggableMap }]">
      <l-popup>You're here!</l-popup>
    </l-marker>
    <l-control position="bottomleft">
      <b-button
        variant="primary"
        @click="mapConfirmClick()"
        id="button--map_confirm"
      >
        Confirm
      </b-button>
    </l-control>
    <l-control-zoom position="bottomright" />
    <l-control position="bottomright">
      <b-button
        size="sm"
        class="bg-white btn-location"
        style="padding: 6px;"
        @click="mapGpsClick()"
        id="button--map_gps"
      >
        <feather-icon icon="TargetIcon" />
      </b-button>
    </l-control>
  </l-map>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import {
  LMap, LTileLayer, LMarker, LPopup, LControl, LControlZoom,
} from 'vue2-leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import VGeosearch from 'vue2-leaflet-geosearch'
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'

import { Icon } from 'leaflet'

/* eslint-disable global-require */
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    VGeosearch,
    LControl,
    BButton,
    LControlZoom,
  },
  props: {
    latitude: {
      type: String,
      default: '0',
    },
    longitude: {
      type: String,
      default: '0',
    },
    zoomMap: {
      type: Number,
      default: 20,
    },
    draggableMap: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
        showMarker: true,
        showPopup: true,
        marker: {
          // icon: '',
          draggable: false,
        },
        autoClose: true, // optional: true|false  - default false
        searchLabel: 'Enter address bos', // optional: string      - default 'Enter address'
        keepResult: false, // optional: true|false  - default false
        updateMap: true,
      },
    }
  },
  watch: {
    longitude(value) {
    },
  },
  methods: {
    mapConfirmClick() {
      // eslint-disable-next-line no-console
      // console.log(this.latitude, ' && ', this.longitude)
      // latitude
      // longitude
      // this.dialog = false
      // this.$emit(
      //   'input',
      //   [this.map.marker.latitude, this.map.marker.longitude].join(',')
      // )
    },
    mapGpsClick() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.latitude = position.coords.latitude
          this.longitude = position.coords.longitude
          // this.map.marker.latitude = position.coords.latitude
          // this.map.marker.longitude = position.coords.longitude
        })
      } else {
        console.log('Geolocation is not supported by this browser.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-location {
  &:focus {
    background-color: #fff !important;
  }
}
</style>
